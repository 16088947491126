class _ShoppingListLocalStorageService {
    static EXPIRING_DAYS = 7;
    _list = null;

    get lsKey() {
        return "endet_shopping";
    }

    getList() {
        if(this._list) return this._list;

        const lsResult = localStorage.getItem(this.lsKey);
        const jsonResult = JSON.parse(lsResult);
        if(!jsonResult) return [];
        this._list = this._mapList(jsonResult);
        return this._list;
    }

    getCompleted() {
        let list = this.getList();
        return list.filter(p => !!p.checked);
    }

    setList(newList) {
        const lsString = JSON.stringify(newList);
        if(!lsString) return;
        localStorage.setItem(this.lsKey, lsString);
        this._list = this._mapList(newList);
    }

    insertIntoList(product, lsKey) {
        let list = this.getList();
        let index = lsKey !== undefined ? lsKey : (product.lsKey !== undefined ? product.lsKey : -1);
        if(index < 0) return this._addToList(product);
        list[index] = product;
        this.setList(list);
    }

    _addToList(product) {
        let list = this.getList();
        list.push(product);
        this.setList(list);
    }

    _mapList(input) {
        return input.map((p, index) => {
                p.lsKey = index;
                return p;
            });
    }

    getProduct(lsKey) {
        let list = this.getList();
        return list[lsKey];
    }

    removeFromList(product) {
        let list = this.getList();
        let index = product.lsKey;
        if(index < 0 || index === undefined || index === null) return;
        list.splice(index, 1);
        this.setList(list);
    }
}

const ShoppingListService = new _ShoppingListLocalStorageService();
export default ShoppingListService;
