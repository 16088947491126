// plop imports section
import { ACTION_REMOVE_FROM_SHOPPING_LIST, ACTION_REMOVE_FROM_SHOPPING_LIST_SUCCESS, ACTION_REMOVE_FROM_SHOPPING_LIST_FAILURE } from '../actions/shopping.actions';
import { ACTION_FETCH_SHOPPING_LIST, ACTION_FETCH_SHOPPING_LIST_SUCCESS, ACTION_FETCH_SHOPPING_LIST_FAILURE } from '../actions/shopping.actions';
import { ACTION_ADD_TO_SHOPPING_LIST, ACTION_ADD_TO_SHOPPING_LIST_SUCCESS, ACTION_ADD_TO_SHOPPING_LIST_FAILURE } from '../actions/shopping.actions';

const BASE_STATE = {
  loading: false,
  success: false,
  error: false,
  data: null
};

const DEFAULT_STATE = {
  addItem: {...BASE_STATE},
  fetch: {...BASE_STATE},
  completed: {...BASE_STATE},
};

const handlers = {};

// plop action handlers section
handlers[ACTION_REMOVE_FROM_SHOPPING_LIST] = function (state, action){
  const newState = {...state};

  return newState;
};

handlers[ACTION_REMOVE_FROM_SHOPPING_LIST_SUCCESS] = function (state, action){
  const newState = {...state};

  return newState;
};

handlers[ACTION_REMOVE_FROM_SHOPPING_LIST_FAILURE] = function (state, action){
  const newState = {...state};

  return newState;
};


handlers[ACTION_FETCH_SHOPPING_LIST] = function (state, action){
  return {
    ...state,
    fetch: {
      ...BASE_STATE,
      loading: true
    }
  };
};

handlers[ACTION_FETCH_SHOPPING_LIST_SUCCESS] = function (state, action){
  return {
    ...state,
    fetch: {
      ...BASE_STATE,
      success: true,
      data: [...action.payload.list]
    },
    completed: {
      ...BASE_STATE,
      success: true,
      data: [...action.payload.completed]
    }
  };
};

handlers[ACTION_FETCH_SHOPPING_LIST_FAILURE] = function (state, action){
  return {
    ...state,
    fetch: {
      ...BASE_STATE,
      error: true
    }
  };
};


handlers[ACTION_ADD_TO_SHOPPING_LIST] = function (state, action){
  return {
    ...state,
    addItem: {
      ...BASE_STATE,
      loading: true
    }
  };
};

handlers[ACTION_ADD_TO_SHOPPING_LIST_SUCCESS] = function (state, action){
  return {
    ...state,
    addItem: {
      ...BASE_STATE,
      success: true
    }
  };
};

handlers[ACTION_ADD_TO_SHOPPING_LIST_FAILURE] = function (state, action){
  return {
    ...state,
    addItem: {
      ...BASE_STATE,
      error: true
    }
  };
};



function shoppingReducer(state = DEFAULT_STATE, action) {
  if(typeof handlers[action.type] === 'function'){
    return handlers[action.type](state, action);
  }
  return state;
}

export default shoppingReducer;
