import React from 'react';
import './ShoppingItem.scss';
import {ReactComponent as TimesIcon} from "../../assets/icons/plus.svg";

const ShoppingItem = ({product, onCardClick, onDeleteClick}) => {
    const checked = product.checked ? 'checked' : '';

    const _onDeleteClickWrapper = (event) => {
        event.stopPropagation();
        if(onDeleteClick && typeof onDeleteClick === 'function') onDeleteClick();
    };

    return (
        <div onClick={onCardClick} className={`ShoppingItem ProductCard card ${checked}`}>
            <div className={`checkbox ${checked}`}/>
            <div className={'product-name'}>{product.product_name}</div>
            <div className={'qta'}>{product.qta}</div>
            <div onClick={_onDeleteClickWrapper.bind(this)} className={'delete-btn'}>
                <TimesIcon/>
            </div>
        </div>
    );
};

export default ShoppingItem;
