// plop actions section
export const ACTION_REMOVE_FROM_SHOPPING_LIST_FAILURE = 'ACTION_REMOVE_FROM_SHOPPING_LIST_FAILURE';
export const ACTION_REMOVE_FROM_SHOPPING_LIST_SUCCESS = 'ACTION_REMOVE_FROM_SHOPPING_LIST_SUCCESS';
export const ACTION_REMOVE_FROM_SHOPPING_LIST = 'ACTION_REMOVE_FROM_SHOPPING_LIST';
export const ACTION_FETCH_SHOPPING_LIST_FAILURE = 'ACTION_FETCH_SHOPPING_LIST_FAILURE';
export const ACTION_FETCH_SHOPPING_LIST_SUCCESS = 'ACTION_FETCH_SHOPPING_LIST_SUCCESS';
export const ACTION_FETCH_SHOPPING_LIST = 'ACTION_FETCH_SHOPPING_LIST';
export const ACTION_ADD_TO_SHOPPING_LIST_FAILURE = 'ACTION_ADD_TO_SHOPPING_LIST_FAILURE';
export const ACTION_ADD_TO_SHOPPING_LIST_SUCCESS = 'ACTION_ADD_TO_SHOPPING_LIST_SUCCESS';
export const ACTION_ADD_TO_SHOPPING_LIST = 'ACTION_ADD_TO_SHOPPING_LIST';

// plop action generators section
export const removeFromShoppingListAction = (payload) => ({ type: ACTION_REMOVE_FROM_SHOPPING_LIST, payload });
export const fetchShoppingListAction = (payload) => ({ type: ACTION_FETCH_SHOPPING_LIST, payload });
export const addToShoppingListAction = (payload) => ({ type: ACTION_ADD_TO_SHOPPING_LIST, payload });
