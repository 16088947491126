import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import './SpesaPage.scss';
import Header from "../../components/Header/Header";
import ShoppingList from "../../components/ShoppingList/ShoppingList";
import FloatingActionButton from "../../components/FloatingActionButton/FloatingActionButton";
import {ReactComponent as PlusIcon} from "../../assets/icons/plus.svg";
import {connect} from "react-redux";
import {openShoppingItemInsertModalAction} from "../../actions/modals.actions";
import {removeFromShoppingListAction} from "../../actions/shopping.actions";

const SpesaPage = ({items, openInsertModal, removeItem}) => {
    const hasCheckedItems = items?.some(o => o.checked);

    const onCompleteShoppingBtnClick = () => {
      const itemsToDelete = items?.filter(o => o.checked);
      for(let item of itemsToDelete) {
          removeItem(item);
      }
    };

    return (
        <DefaultLayout>
            <Header
                withBackground={false}
                subtitle={`Ecco la tua lista della spesa`}
            />
            <div className={"SpesaPage"}>
                <div className={'content'}>
                    <ShoppingList/>
                </div>
                <FloatingActionButton onClickEvent={() => openInsertModal()}>
                    <PlusIcon/>
                </FloatingActionButton>
                {hasCheckedItems && <div onClick={onCompleteShoppingBtnClick.bind(this)} className={'btn add-custom-btn'}>Concludi spesa</div>}
            </div>
        </DefaultLayout>
    );
};

export default connect(
    state => ({
        items: state.shopping.fetch.data
    }),
    {
        openInsertModal: openShoppingItemInsertModalAction,
        removeItem: removeFromShoppingListAction
    }
)(SpesaPage);
