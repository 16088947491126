import React, {useEffect, useState} from 'react';
import './ShoppingList.scss';
import ShoppingItem from "../ShoppingItem/ShoppingItem";
import {connect} from "react-redux";
import {
    addToShoppingListAction,
    fetchShoppingListAction,
    removeFromShoppingListAction
} from "../../actions/shopping.actions";

const ShoppingList = ({items, fetchItems, updateItem, removeItem}) => {
    const [autoSort, setAutoSort] = useState(false);

    useEffect(() => {
        if(items === null) fetchItems();
    }, [items, fetchItems]);

    const _onCardClick = (item) => {
        updateItem({
            ...item,
            checked: !item.checked
        });
    };

    const _onCardDeleteClick = (item) => {
        removeItem(item);
    };

    const sortedItems = autoSort ?
        [...items]?.sort((a, b) =>
            a.checked === b.checked ?
            (a.lsKey > b.lsKey ? 1 : -1)
                :
            a.checked ? 1 : -1) : items;
    const list = sortedItems?.map(item =>
        <ShoppingItem
            product={item}
            onCardClick={() => _onCardClick(item)}
            onDeleteClick={() => _onCardDeleteClick(item)}
            key={`shopping-item-${item.lsKey}`}
        />
    );

    return (
        <div className={`ShoppingList`}>
            <div className={'list-header'}>
                <div className={'btn btn-simple'} onClick={() => setAutoSort(!autoSort)}>{autoSort ? 'Disattiva auto-sort' : 'Attiva auto-sort'}</div>
            </div>
            <div className={'list'}>
                {list}
            </div>
        </div>
    );
};

export default connect(
    state => ({
        items: state.shopping.fetch.data
    }),
    {
        fetchItems: fetchShoppingListAction,
        removeItem: removeFromShoppingListAction,
        updateItem: addToShoppingListAction
    }
)(ShoppingList);
