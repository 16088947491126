import React, {useState} from 'react';
import './ShoppingItemInsertModal.scss';
import {ReactComponent as ArrowLeftIcon} from "../../assets/icons/arrow-left.svg";
import {connect} from "react-redux";
import {
    closeShoppingItemInsertModalAction,
} from "../../actions/modals.actions";
import {addToShoppingListAction} from "../../actions/shopping.actions";

const ShoppingItemInsertModal = ({open, closeModal, addItem}) => {
    const [nameValue, setNameValue] = useState("");
    const [qtaValue, setQtaValue] = useState("");
    const image_url = "/placeholder.png";

    const resetModal = () => {
        setNameValue("");
        setQtaValue("");
    };

    const _onCloseBtnClick = () => {
        resetModal();
        closeModal();
    };

    const _onAddBtnClick = () => {
        addItem({
            product_name: nameValue,
            qta: qtaValue,
            image_thumb_url: image_url
        });
        resetModal();
        closeModal();
    };

    return (
        <div className={`ShoppingItemInsertModal ${open ? 'open' : ''}`}>
            <div className={'header'}>
                <div onClick={_onCloseBtnClick.bind(this)} className={'close-btn'}>
                    <ArrowLeftIcon/>
                </div>
                <div className={'app-title'}>Aggiungi un prodotto</div>
            </div>
            <div className={'content'}>
                <div className={'form'}>
                    <div className={'input-group card'}>
                        <div className={'label'}>Nome prodotto</div>
                        <input type="text" placeholder={'Pane e Nutella'} value={nameValue} onChange={e => setNameValue(e.target.value)}/>
                    </div>
                    <div className={'input-group card'}>
                        <div className={'label'}>Quantità</div>
                        <input type="text" placeholder={'100 g'} value={qtaValue} onChange={e => setQtaValue(e.target.value)}/>
                    </div>
                    <div onClick={_onAddBtnClick.bind(this)} className={'btn btn-success'}>AGGIUNGI PRODOTTO</div>
                </div>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        open: state.modals.shoppingListItemInsertModal?.open,
    }),
    {
        closeModal: closeShoppingItemInsertModalAction,
        addItem: addToShoppingListAction
    }
)(ShoppingItemInsertModal);
