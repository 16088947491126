import { takeEvery, call, put } from 'redux-saga/effects';
// plop imports section
import {
  ACTION_ADD_TO_SHOPPING_LIST_SUCCESS,
  ACTION_FETCH_SHOPPING_LIST_SUCCESS,
  ACTION_REMOVE_FROM_SHOPPING_LIST,
  ACTION_REMOVE_FROM_SHOPPING_LIST_SUCCESS
} from '../actions/shopping.actions';
import { ACTION_FETCH_SHOPPING_LIST } from '../actions/shopping.actions';
import { ACTION_ADD_TO_SHOPPING_LIST } from '../actions/shopping.actions';
import ShoppingListService from "../libs/ShoppingListLocalStorageService";

// plop saga action handlers section
function* onRemoveFromShoppingList(action) {
  const { payload } = action;
  ShoppingListService.removeFromList(payload);
  yield put({
    type: ACTION_REMOVE_FROM_SHOPPING_LIST_SUCCESS
  });
  yield put({
    type: ACTION_FETCH_SHOPPING_LIST
  });
}

function* onFetchShoppingList(action) {
  const { payload } = action;
  yield put({
    type: ACTION_FETCH_SHOPPING_LIST_SUCCESS,
    payload: {
      list: ShoppingListService.getList(),
      completed: ShoppingListService.getCompleted()
    }
  });
}

function* onAddToShoppingList(action) {
  const { payload } = action;
  ShoppingListService.insertIntoList(payload);
  yield put({type: ACTION_ADD_TO_SHOPPING_LIST_SUCCESS});
  yield put({type: ACTION_FETCH_SHOPPING_LIST});
}


function* shoppingSaga() {
  // plop sagas section
yield takeEvery(ACTION_REMOVE_FROM_SHOPPING_LIST, onRemoveFromShoppingList);
yield takeEvery(ACTION_FETCH_SHOPPING_LIST, onFetchShoppingList);
yield takeEvery(ACTION_ADD_TO_SHOPPING_LIST, onAddToShoppingList);
}

export default shoppingSaga;
